/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import "animate.css";
import { storageService } from "../../services";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import { useGuard } from "../../hooks/useGuard";
import robot from "../../assets/mimic.svg";
import chat from "../../assets/chat.svg";
import search from "../../assets/search.svg";
import { getWhatsappLogsAsync } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import menuAlt from "../../assets/icons/menu-alt.svg";
import { FaRegUserCircle, FaRobot } from "react-icons/fa";
import MimicIcon from "../../assets/mimic.svg";

export default function WhatsAppLogs() {
	useGuard();
	const msgBox = useRef(null);
	const bottomBox = useRef(null);
	const { whatsapp_logs } = useSelector((state) => state.mimic);

	const [filterby, setFilterby] = useState({
		id: "alltime",
		title: "All Time",
	});
	const dispatch = useDispatch();
	const [logs, setLogs] = useState([]);
	const [filteredLogs, setFilteredLogs] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [groupedMessages, setGroupedMessages] = useState([]);
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [selectedPhonenumber, setSelectedPhonenumber] = useState("");
	const [selectedCustomerName, setSelectedCustomerName] = useState("");
	const [phoneNumberFiltered, setPhoneNumberFiltered] = useState([]);
	const [phoneSearch, setPhoneSearch] = useState("");
	const authData = storageService.getAuthData();

	const filterDates = [
		{
			id: "alltime",
			title: "All Time",
		},
		{
			id: "today",
			title: "Today",
		},
		{
			id: "thisweek",
			title: "This Week",
		},
		{
			id: "thismonth",
			title: "This Month",
		},
	];

	const formattedDateTime = (date) => {
		let logDate = new Date(date);
		// Format the date to "21/05/2024"
		let formattedDate = logDate.toLocaleDateString("en-GB");

		// Format the time to "15:41"
		let formattedTime = logDate.toLocaleTimeString("en-GB", {
			hour: "2-digit",
			minute: "2-digit",
		});
		return `${formattedDate} at ${formattedTime}`;
	};
	const fetchLogs = async () => {
		dispatch(getWhatsappLogsAsync());
	};

	const groupMessages = () => {
		const groupedMessages = filteredLogs.reduce((acc, message) => {
			const phoneNumber = message.isBotResponse ? message.to : message.from;
			if (!acc[phoneNumber]) {
				acc[phoneNumber] = [];
			}
			acc[phoneNumber].push(message);
			return acc;
		}, {});

		setGroupedMessages(groupedMessages);
	};

	const getPhoneNumbers = () => {
		const uniquePhoneNumbers = new Set();

		return filteredLogs
			.filter((message) => message.isBotResponse === false)
			.map((message) => {
				const identifier = `${message.from}-${message.customerName}`;
				if (!uniquePhoneNumbers.has(identifier)) {
					uniquePhoneNumbers.add(identifier);
					return {
						phoneNumber: message.from,
						customerName: message.customerName,
					};
				}
				return null;
			})
			.filter((item) => item !== null); // Filter out null values (duplicate entries)
	};

	const downloadMedia = (url) => {
		// Create a URL object to easily extract the filename (if needed)
		const urlObj = new URL(url);
		const filename = urlObj.pathname.split("/").pop(); // Get the last part of the path (filename)

		// Create a new anchor element to open the URL in a new tab
		const link = document.createElement("a");
		link.href = url;
		link.target = "_blank"; // Open in a new tab
		link.rel = "noopener noreferrer"; // Security best practice
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	// Function to get the first day of the current month
	const getFirstDayOfMonth = () => {
		const today = new Date();
		return new Date(today.getFullYear(), today.getMonth(), 1); // Set to the first day of the month
	};

	const getLastSunday = () => {
		const today = new Date();
		const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
		const lastSunday = new Date(today);
		lastSunday.setDate(today.getDate() - dayOfWeek);
		lastSunday.setHours(0, 0, 0, 0); // Set to the start of the day
		return lastSunday;
	};

	// Fetch logs when the component mounts
	useEffect(() => {
		fetchLogs();
	}, []);

	useEffect(() => {
		setLogs(whatsapp_logs);
	}, [whatsapp_logs]);

	useEffect(() => {
		if (typeof logs !== "undefined") {
			const today = new Date();

			if (filterby.id === "today") {
				// Get today's date
				const today_ = new Date().toISOString().split("T")[0];
				// Filter chats created today
				const chatsToday = logs.filter((chat) =>
					chat.createdAt.startsWith(today_)
				);
				setFilteredLogs(chatsToday);
			} else if (filterby.id === "thisweek") {
				// Get the date of last Sunday and first day of this month
				const lastSunday = getLastSunday();
				const chatsThisWeek = logs.filter((chat) => {
					const chatDate = new Date(chat.createdAt);
					return chatDate >= lastSunday && chatDate <= today;
				});
				setFilteredLogs(chatsThisWeek);
			} else if (filterby.id === "thismonth") {
				const firstDayOfMonth = getFirstDayOfMonth();
				const chatsThisMonth = logs.filter((chat) => {
					const chatDate = new Date(chat.createdAt);
					return chatDate >= firstDayOfMonth && chatDate <= today;
				});
			} else {
				setFilteredLogs(logs);
			}
		}
	}, [logs, filterby]);

	useEffect(() => {
		groupMessages();
		setPhoneNumbers(getPhoneNumbers());
	}, [filteredLogs]);

	useEffect(() => {
		const interval = setInterval(() => {
			fetchLogs();
		}, 60000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		let ft = phoneNumbers.filter(({ phoneNumber, customerName }, index) => {
			// Normalize both phoneNumber and phoneSearch by removing the '+' character
			let normalizedPhoneNumber = phoneNumber.toString().replace("+", "");
			let normalizedPhoneSearch = phoneSearch.replace("+", "");

			if (normalizedPhoneSearch?.startsWith("0")) {
				normalizedPhoneSearch = normalizedPhoneSearch.slice(1);
			}

			// Perform the search
			if (normalizedPhoneNumber.includes(normalizedPhoneSearch)) {
				return true;
			} else if (
				customerName
					.toString()
					?.toLowerCase()
					.includes(phoneSearch?.toLowerCase())
			) {
				return true;
			} else {
				return false;
			}
		});
		setPhoneNumberFiltered(ft);
	}, [phoneNumbers, phoneSearch]);

	useEffect(() => {
		if (selectedPhonenumber !== "") {
			if (bottomBox.current) {
				bottomBox.current.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [selectedPhonenumber]);

	const [currentPage, setCurrentPage] = useState(1);
	const [phoneNumbersPerPage, setPhoneNumbersPerPage] = useState(10);

	// Get current phone numbers
	const [indexOfLastPhoneNumber, setIndexofLastPhoneNumber] = useState(0);
	const [indexOfFirstPhoneNumber, setIndexofFirstPhoneNumber] = useState(0);
	const [currentPhoneNumbers, setCurrentPhoneNumbers] = useState([]);
	const [pagesCount, setPagesCount] = useState(0);
	const [pages, setPages] = useState([0]);
	const [pagesToShow, setPagesToShow] = useState([]);
	const [showsideFilters, setShowSideFilters] = useState(false);
	const getCenterElements = (arr, i) => {
		const n = arr.length;

		if (i === 0) {
			// Return i and the next 4 elements
			return arr.slice(i, i + 5);
		} else if (i === 1) {
			// Return i-1, i, and next 3 elements
			return arr.slice(i - 1, i + 4);
		} else if (i === 2) {
			// Return i-2, i-1, i, and the next 2 elements
			return arr.slice(i - 2, i + 3);
		} else if (i === n - 2) {
			// Return i-3, i-2, i-1, and i (last element)
			return arr.slice(Math.max(i - 3, 0), i + 2);
		} else if (i === n - 1) {
			// Return i-3, i-2, i-1, and i (last element)
			return arr.slice(Math.max(i - 4, 0), i + 1);
		} else {
			// General case: return 5 elements centered on index i
			return arr.slice(Math.max(i - 2, 0), Math.min(i + 3, n));
		}
	};

	useEffect(() => {
		let indexOfLastPhoneNumber_ = currentPage * phoneNumbersPerPage;
		let indexOfFirstPhoneNumber_ =
			indexOfLastPhoneNumber_ - phoneNumbersPerPage;
		let currentPhoneNumbers_ = [];
		let pagesCount_ =
			phoneNumbersPerPage !== 0
				? Math.ceil(phoneNumberFiltered.length / phoneNumbersPerPage)
				: 0;
		let pages_ = Array.from({ length: pagesCount_ }, (_, i) => i + 1);
		let pagesToShow_ = getCenterElements(pages_, currentPage - 1);
		currentPhoneNumbers_ = phoneNumberFiltered.slice(
			indexOfFirstPhoneNumber_,
			indexOfFirstPhoneNumber_ + phoneNumbersPerPage
		);

		console.log(
			indexOfFirstPhoneNumber_,
			currentPhoneNumbers_,
			phoneNumberFiltered,
			pages_,
			pagesToShow_
		);
		setIndexofLastPhoneNumber(indexOfLastPhoneNumber_);
		setIndexofFirstPhoneNumber(indexOfFirstPhoneNumber_);
		setCurrentPhoneNumbers(currentPhoneNumbers_);
		setPagesCount(pagesCount_);
		setPages(pages_);
		setPagesToShow(pagesToShow_);
	}, [phoneNumberFiltered, currentPage, phoneNumbersPerPage]);

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Mimic Logs" />
				<div className="h-full p-[10px]">
					<div className="h-full w-full bg-gray-100 overflow-hidden">
						{/* <h1 className='text-2xl text-grey-800 p-[30px] font-bold shadow-md'>Eric Kayser WhatsApp Logs</h1> */}
						<div className="h-full flex flex-row">
							<div
								className="w-[450px] bg-dark-gray3 h-full overflow-y-auto
								flex flex-col
								"
							>
								{showsideFilters && (
									<div
										className={`p-[20px] !pb-0 bg-dark-gray1
								 ${
										showsideFilters
											? "animate__animated animate__slideInDown"
											: "animate__animated animate__slideOutDown"
									}	
								`}
									>
										<div
											className="flex flex-row justify-center items-center w-100 
									bg-dark-gray2 p-[10px] rounded-[5px] "
										>
											<input
												className="w-full text-white bg-dark-gray2 
											rounded-[5px] focus:outline-none text-sm !text-dmsans"
												placeholder="Search phone number or name"
												onChange={(e) => setPhoneSearch(e.target.value)}
											/>
											<div className="">
												<img src={search} className="w-[25px]" />
											</div>
										</div>

										<div
											className="h-[50px] mt-[10px] mb-[20px] 
									rounded-md flex flex-row justify-between w-full"
										>
											<div className="mt-[6px]">
												<input
													type="number"
													min={1}
													className="!w-[50px] px-[5px] bg-dark-gray2 
											rounded-[5px] text-[12px] leading-[30px]
											h-[30px] text-white"
													defaultValue={phoneNumbersPerPage}
													onChange={(e) => {
														let val = e.target.value;
														setPhoneNumbersPerPage(Number(val));
													}}
												></input>
												<div className="text-light-gray2 mt-[5px] text-[10px]">
													per page
												</div>
											</div>

											<div className="relative py-[10px] w-[100px]">
												<div
													className="page-filter !w-[150px] !top-0 !my-[5px] bg-dark-gray2 
											!h-[30px] leading-[30px] px-[15px] rounded-[5px]
											text-light-gray1 flex justify-between text-[12px] cursor-pointer font-dmsans"
													onClick={() => {
														setShowFilters(!showFilters);
													}}
												>
													<div>{filterby.title}</div>
													<div
														className={`icon !h-[30px] ${
															showFilters ? "up" : "down"
														} `}
													></div>
												</div>
												{showFilters && (
													<div className="absolute top-[40px] !bg-dark-gray1 right-[0px] page-filter-options font-dmsans">
														{filterDates.map((thisDate, index) => {
															return (
																<div
																	className={`page-filter-option ${
																		thisDate.id === filterby.id
																			? "active !bg-primary !text-white font-dmsans"
																			: ""
																	}`}
																	key={index}
																	onClick={() => {
																		setFilterby(thisDate);
																	}}
																>
																	{thisDate.title}
																</div>
															);
														})}
													</div>
												)}
											</div>
										</div>
									</div>
								)}

								<div className="p-[20px] pt-0 bg-dark-gray3">
									<div
										className={`drawer w-[20px] !h-[30px]
										cursor-pointer rounded-md m-auto !mb-[30px]
										downIcon  ${showsideFilters ? "up" : "down"} 
										`}
										onClick={() => {
											if (!showsideFilters === false) {
												setShowFilters(false);
											}
											setShowSideFilters(!showsideFilters);
										}}
									></div>
									<div className="text-gray-400 text-sm text-dmsans">
										{currentPhoneNumbers.length} phone numbers (Page{" "}
										{currentPage})
									</div>
									{currentPhoneNumbers.map(
										({ phoneNumber, customerName }, index) => {
											let thisCustomerName =
												customerName.length > 10
													? customerName.slice(0, 10) + "..."
													: customerName;
											return (
												<div key={index}>
													{typeof groupedMessages[phoneNumber] !==
														"undefined" && (
														<div
															className={`w-full h-auto min-h-[50px] my-2 font-dmsans
						text-white text-md text-center px-[15px] py-[13px] cursor-pointer rounded-[5px] bg-dark-gray1 flex justify-between 
						${phoneNumber === selectedPhonenumber ? "bg-orange-600" : ""}
						`}
															onClick={() => {
																setSelectedPhonenumber(phoneNumber);
																setSelectedCustomerName(customerName);
															}}
														>
															<div className="text-[14px] text-left text-white saira-400">
																{phoneNumber
																	.toString()
																	.replace("whatsapp:", "")}
																{!customerName.startsWith("+") && (
																	<div className="text-[10px] px-[10px] bg-dark-gray3 rounded-sm">
																		{customerName}
																	</div>
																)}
															</div>
															<span
																className={` ${
																	phoneNumber === selectedPhonenumber
																		? "bg-orange-800 text-orange-300"
																		: "bg-dark-gray3 text-gray-400"
																} 
							flex h-[22px] mt-[1px] text-xs p-[2px] rounded-[1px] font-bold min-w-[30px] justify-center items-center`}
															>
																{groupedMessages[phoneNumber].length}
															</span>
														</div>
													)}
												</div>
											);
										}
									)}

									{pagesToShow.length > 1 && (
										<div className="w-full h-[49px] mt-[50px] flex rounded-sm justify-between">
											<div
												className={`h-[40px] !px-[10px] leading-[40px] 
													text-dmsans cursor-pointer rounded-sm
													${
														currentPage !== 1
															? "bg-dark-gray2 text-white"
															: "bg-dark-gray2 text-dark-gray3"
													}
													`}
												onClick={() => {
													if (currentPage > 1) {
														setCurrentPage(currentPage - 1);
													}
												}}
											>
												Prev
											</div>
											<div className="flex gap-[5px] w-full justify-center">
												{pagesToShow.map((thisPage, index) => {
													return (
														<div
															className={`h-[40px] !px-[10px] leading-[40px] 
																text-white text-dmsans cursor-pointer rounded-sm
																${currentPage === thisPage ? "bg-orange-600" : "bg-dark-gray2"}
																`}
															key={index}
															onClick={() => {
																setCurrentPage(thisPage);
															}}
														>
															{thisPage}
														</div>
													);
												})}
											</div>

											<div
												className={`h-[40px] !px-[10px] leading-[40px] 
												text-dmsans cursor-pointer rounded-sm
												${
													currentPage < pages.length
														? "text-white bg-dark-gray2"
														: "bg-dark-gray2 text-dark-gray3"
												}
												`}
												onClick={() => {
													if (currentPage < pages.length) {
														setCurrentPage(currentPage + 1);
													}
												}}
											>
												Next
											</div>
										</div>
									)}
								</div>
							</div>
							<div className="bg-dark-gray2 w-full overflow-y-auto pb-[100px]">
								{selectedPhonenumber !== "" && (
									<div>
										<div className="text-dmsans text-[15px] p-[20px] text-gray-300 border-b border-gray-300 bg-opacity-70 backdrop-blur-lg">
											Logs for{" "}
											<span className="text-orange-300 text-dmsans">
												{selectedPhonenumber}
											</span>
											<span className="text-[12px] text-dmsans p-[10px] bg-dark-gray2 mx-[5px] rounded-sm">
												{selectedCustomerName}
											</span>
										</div>
										{typeof groupedMessages[selectedPhonenumber] !==
											"undefined" && (
											<div className="p-[24px]" ref={msgBox}>
												{groupedMessages[selectedPhonenumber]
													.toReversed()
													.map((this_log, index) => {
														return (
															<div
																className={`mb-[20px] w-full
																	${index === this_log.length - 1 ? "last_message" : ""}
																`}
																id={`${
																	index === this_log.length - 1
																		? "lastmsg"
																		: "others"
																}`}
																key={index}
															>
																{this_log.isBotResponse === false ? (
																	<div className="mr-auto w-fit flex flex-row gap-2 items-center text-gray-100 saira-400">
																		<FaRegUserCircle
																			size={20}
																			color="#F5F5F5"
																		/>
																		<div>
																			<div className="w-fit justify-end bg-dark-gray1 p-[10px] rounded-[5px] saira-600 text-[14px] text-gray-100">
																				{this_log.body}
																			</div>
																		</div>
																	</div>
																) : (
																	<div className="text-dmsans ml-auto w-fit flex flex-row items-center align-center text-right gap-2">
																		<div className="w-fit bg-dark-gray1 p-[10px] rounded-[5px] saira-400 text-[14px] text-gray-300 whitespace-pre-line">
																			{this_log.body}
																		</div>
																		{/* <MimicIcon size={20} color="#F5F5F5" /> */}
																		<img
																			src={MimicIcon}
																			alt="img"
																			className="h-5"
																		/>
																	</div>
																)}
																{typeof this_log.mediaUrl !== "undefined" && (
																	<div>
																		{this_log.mediaUrl.toString().length >
																			5 && (
																			<div
																				className={`p-[10px] w-[300px] 
																				bg-dark-gray1 my-[10px] rounded-md 
																				${
																					this_log.isBotResponse
																						? "ml-auto text-right mr-[30px] justify-end"
																						: "ml-[30px]"
																				}
																				`}
																			>
																				{this_log.mediaContentType
																					.toLowerCase()
																					.includes("image") && (
																					<img
																						src={this_log.mediaUrl}
																						className="w-full h-[150px] object-contain"
																					/>
																				)}

																				{!this_log.mediaContentType
																					.toLowerCase()
																					.includes("image") && (
																					<div
																						className="w-full m-y-[10px] p-y-[10px] 
																						font-bold text-dmsans text-[15px]
																						"
																					>
																						Document
																					</div>
																				)}
																				<div
																					className="w-full h-[40px] text-orange-500 
																					rounded-full cursor-pointer text-dmsans pt-[10px]"
																					onClick={() =>
																						downloadMedia(this_log.mediaUrl)
																					}
																				>
																					Open{" "}
																					{this_log.mediaContentType
																						.toLowerCase()
																						.includes("image")
																						? "image"
																						: "document"}
																				</div>
																			</div>
																		)}
																	</div>
																)}
																<div
																	className={`flex text-sm p-1 ${
																		this_log.isBotResponse
																			? "ml-auto text-right mr-[30px] justify-end"
																			: "ml-[30px]"
																	}`}
																>
																	<div className="text-[11px] text-light-gray1 text-dmsans mb-[5px] mr-[10px] rounded-sm text-orange-100">
																		{this_log.isBotResponse
																			? "Mimic"
																			: selectedCustomerName}{" "}
																		|{" "}
																	</div>

																	<div className="!text-[11px] saira-600 text-orange-200 text-dmsans">
																		{formattedDateTime(this_log.createdAt)}
																	</div>
																</div>
															</div>
														);
													})}
												<div ref={bottomBox} className="p-3"></div>
											</div>
										)}
									</div>
								)}
								{selectedPhonenumber === "" && (
									<div className="p-[20px]">
										<div className="text-[16px] text-light-gray2 text-dmsans">
											Select a Phone number to view logs
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
