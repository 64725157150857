import React, { useContext, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import TopBar from "../../components/TopBar";
import DetailsBox from "../../components/DetailsBox";
import { formatToCurrency, getDate } from "../../utils";
import Title from "../../components/Title";
import {
	adminCancelOrderAsync,
	adminCompleteOrderAsync,
	getTransactionDataAsync,
	updateDeliveryStatusAsync,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGuard } from "../../hooks/useGuard";
import { mainFunctions } from "../../providers/MainProvider";
import ConfirmCancelOrder from "../../modals/ConfirmCancelOrder";

export default function TransactionDetails() {
	useGuard();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const { transactionData, loading } = useSelector(
		(state) => state.transaction
	);
	const { setShowModal, setModalData } = useContext(mainFunctions);

	useEffect(() => {
		if (id) {
			dispatch(getTransactionDataAsync(id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const handleSubmit = () => {
		let data = {
			transactionId: +id,
			deliveryStatus: "Dispatched",
		};
		dispatch(
			updateDeliveryStatusAsync(data, async (_) => {
				dispatch(getTransactionDataAsync(id));
			})
		);
	};
	const handleComplete = () => {
		const postData = transactionData?.purchaseCode;
		dispatch(
			adminCompleteOrderAsync(postData, () => {
				dispatch(getTransactionDataAsync(id));
			})
		);
	};
	const continueCancel = () =>{
		const postData = transactionData?.purchaseCode;
		dispatch(
			adminCancelOrderAsync(postData, () => {
				dispatch(getTransactionDataAsync(id));
			})
		);
	}
	const handleCancel = () => {
		setModalData(<ConfirmCancelOrder cancelOrder={continueCancel} />);
		setShowModal(true);
	};

	return (
		<div className="body">
			<Sidebar />
			<div className="mainbar">
				<TopBar title="Transaction Details" small={true} />
				<div className="full-mainbar-container">
					<div className="row pt-5 border-bottom">
						<DetailsBox
							title="Customer ID:"
							value={transactionData?.customerId?.substring(
								0,
								transactionData?.customerId?.indexOf("-")
							)}
						/>
						<DetailsBox
							title="Customer Name:"
							value={transactionData.customerName}
						/>
						<DetailsBox
							title="Email Address:"
							value={transactionData.customerEmail}
						/>
						<DetailsBox
							title="Phone Number:"
							value={transactionData?.phoneNumber}
						/>
					</div>
					<div
						className={`row ${
							transactionData.deliveryStatus && "border-bottom"
						}`}
					>
						<DetailsBox
							title="Last Transaction:"
							value={getDate(transactionData.lastTransactionDate)}
						/>
						<DetailsBox
							title="Mode of Payment"
							value={transactionData.modeOfPayment}
						/>
						<DetailsBox
							title="Payment Status"
							value={transactionData.status}
							cssClass={`status-${
								transactionData.status === "Pending" ? "pending" : "success"
							}`}
						/>
						<DetailsBox
							title="Store of Purchase"
							value={transactionData.storeOfPurchase}
						/>
					</div>
					<div className="row">
						<DetailsBox
							title="Transaction Type"
							value={transactionData?.transactionDetail}
						/>
						{transactionData.deliveryStatus && (
							<DetailsBox
								title="Delivery Status"
								value={transactionData.deliveryStatus}
								cssClass={`status-${
									transactionData.deliveryStatus === "Dispatched"
										? "success"
										: transactionData.status === "Processing"
											? "pending"
											: "failed"
								}`}
							></DetailsBox>
						)}
						{transactionData.deliveryAddress && (
							<DetailsBox
								title="Delivery Address"
								value={transactionData.deliveryAddress}
							/>
						)}
						{!transactionData.isOrderFulfilled &&
							!transactionData.isOrderPreviouslyUpdated &&
							transactionData.deliveryStatus === "Processing" && (
								<div className="mb-3">
									
									<div
										className={`btn_ !text-glow !border-[1px] border-glow !border-solid !bg-dark-gray1 mb-3 top_btn ${
											loading ? "disabled" : ""
										}`}
										onClick={
											!loading ? () => navigate(`/orders/${id}/edit`) : () => {}
										}
									>
										EDIT ORDER
									</div>
									<div
										className={`btn_ !text-red-600 !border-[1px] border-red-600 !border-solid !bg-dark-gray1 mb-3 top_btn ${
											loading ? "disabled" : ""
										}`}
										onClick={!loading ? handleCancel : () => {}}
									>
										CANCEL ORDER
									</div>
								</div>
							)}
						{!transactionData.isOrderFulfilled &&
							transactionData.deliveryStatus === "Processing" && (
								<div className="mb-3">
									<div
										className={`btn_ !bg-glow border-[1px] border-glow border-solid !text-dark-gray1 mb-3 ${
											loading ? "disabled" : ""
										}`}
										onClick={!loading ? handleSubmit : () => {}}
									>
										DISPATCH ORDER
									</div>
								</div>
							)}
						{!transactionData.isOrderFulfilled &&
							transactionData.deliveryStatus === "Dispatched" && (
								<div className="mb-3">
									<div
										className={`
											flex justify-center items-center w-max min-w-[196px] h-[50px] rounded-[25px] font-medium text-[14px] leading-[24px] uppercase px-8 cursor-pointer
											!bg-glow border-[1px] border-glow border-solid !text-dark-gray1 mb-3 ${
											loading ? "disabled" : ""
										}`}
										onClick={!loading ? handleComplete : () => {}}
									>
										COMPLETE ORDER
									</div>
								</div>
							)}

						
						{!transactionData.isOrderFulfilled &&
							transactionData.transactionDetail === "PickUp" &&
							transactionData.status === "Successful" && (
								<div className="mb-3">
									<div
										className={`
											flex justify-center items-center w-max min-w-[196px] h-[50px] rounded-[25px] font-medium text-[14px] leading-[24px] uppercase px-8 cursor-pointer
											!bg-glow border-[1px] border-glow border-solid text-dark-gray1 mb-3 ${
											loading ? "disabled" : ""
										}`}
										onClick={!loading ? handleComplete : () => {}}
									>
										COMPLETE ORDER
									</div>
								</div>
							)}
						{!transactionData.isOrderFulfilled &&
							!transactionData.isOrderPreviouslyUpdated &&
							transactionData.transactionDetail === "PickUp" && (
								<div className="mb-3">
									<div
										className={`btn_ !text-glow border-[1px] border-glow border-solid bg-dark-gray1 mb-3 top_btn ${
											loading ? "disabled" : ""
										}`}
										onClick={
											!loading ? () => navigate(`/orders/${id}/edit`) : () => {}
										}
									>
										EDIT ORDER
									</div>
									<div
										className={`btn_ !text-red-600 border-[1px] border-red-600 border-solid bg-dark-gray1 mb-3 top_btn ${
											loading ? "disabled" : ""
										}`}
										onClick={!loading ? handleCancel : () => {}}
									>
										CANCEL ORDER
									</div>
								</div>
							)}
					</div>

					<Title title="All Items Purchased" />

					<div className="border border-solid border-secondary
					w-full max-w-[616px] h-auto bg-dark-gray1 box-border rounded-lg px-4
					">
						{transactionData.purchasedItem?.map((row, index) => {
							return (

								
								<div className="
								w-full max-w-[616px] h-auto bg-dark-gray1 rounded-md px-4
								">
									<div className="flex justify-between" key={index}>
										<div className="flex justify-start">
											<div className="text-left w-[50px] max-w-[50px] font-dmsans font-medium text-[16px] leading-[20px] text-light-gray2 py-3">{index+1}.</div>
											<div className="text-left font-dmsans font-medium text-[16px] leading-[20px] text-light-gray2 py-3">{row.name}</div>
										</div>
										<div className="text-left w-[150px] max-w-[150px] font-dmsans font-normal text-[14px] leading-[17px] text-right text-light-gray2 py-3">
											₦{formatToCurrency(row.unitPrice, 2)} x {row.quantity}
										</div>
									</div>
									{row.specialRequest && row.specialRequest !== 'string' && (
										<p className="special_request">{
											 <span>{row.specialRequest}</span>
											}</p>
									)}
									{index+1 < transactionData.purchasedItem.length &&
										<div className="h-[1px] bg-secondary w-full"></div>
									}
								</div>
							);
						})}
					</div>
					<div className="w-[616px] h-[50px] bg-dark-gray1 border !border-glow border-solid box-border rounded-lg mt-[5px] flex justify-between px-8 py-3">
						<div className="font-dmsans font-medium text-[14px] leading-[20px] text-glow">Total Purchase Value</div>
						<div className="font-dmsans font-normal text-[14px] text-glow">
							₦{formatToCurrency(transactionData.totalPurchasedValue, 2)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
